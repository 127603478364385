<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h4>Images</h4>
      </v-col>
    </v-row>
    <!--Draggable image blocks-->
    <div v-if="images.length >= 1">
      <v-row>
        <v-col
          cols="12"
          style="display: flex; align-items: center; justify-content: center"
        >
          <!--Draggable thumbnails-->
          <draggable
            :list="images"
            group="images"
            class="flex-container"
            handle=".handle"
          >
            <v-card
              v-for="(item, i) in images"
              :key="item.url"
              @mouseenter="$set(hover, 'display', i)"
              @mouseleave="$set(hover, 'display', false)"
              class="handle"
            >
              <v-img
                :src="item.url"
                :lazy-src="item.url"
                :alt="item.alt"
                max-width="100%"
                width="100%"
              />
              <!--Overlay-->
              <v-overlay v-show="hover.display === i" opacity="0.5" absolute>
                <!--Preview-->
                <v-btn left icon @click="previewImage(item.url)"
                  ><v-icon>mdi-eye</v-icon></v-btn
                >
                <!--Alt tag-->
                <v-btn text small @click="$set(altDialog, i, true)">
                  <v-icon x-small>mdi-pencil</v-icon>ALT</v-btn
                >
                <!--Delete-->
                <v-btn icon right @click="$emit('deleteImage', item)">
                  <v-icon>mdi-delete</v-icon></v-btn
                >
              </v-overlay>
              <AltTagModal
                :image="item"
                :index="i"
                :altDialog="altDialog"
                @closeAltModal="$set(altDialog, i, false)"
                @saveImgAlt="
                  $emit('saveImgAlt', { value: $event, image: item, index: i })
                "
              />
            </v-card>
            <!--Add new Image Button-->
            <v-card
              @click="$refs.input1.click()"
              :disabled="image_loading"
              flat
            >
              <v-img
                src="../../../../assets/fallback-img.svg"
                max-width="100%"
                width="100%"
              />
              <!--Overlay-->
              <v-overlay opacity="0" absolute>
                <v-btn text light v-if="!image_loading">
                  <v-icon left>mdi-upload</v-icon> Upload Image
                </v-btn>
                <v-progress-circular
                  v-else
                  color="black"
                  :value="uploadValue"
                  height="10"
                ></v-progress-circular>
              </v-overlay>
            </v-card>
          </draggable>
        </v-col>
      </v-row>
    </div>
    <!--Empty state-->
    <ProductImageUploadEmptyState
      v-else
      :loading="image_loading"
      :progress="uploadValue"
      @uploadImage="$refs.input1.click()"
    />
    <!--File input field-->
    <input
      type="file"
      ref="input1"
      style="display: none"
      @change="uploadNewImage"
      accept=".png, .jpg, .jpeg"
    />
  </div>
</template>
<script>
import firebase from "firebase";
import draggable from "vuedraggable";
export default {
  name: "ProductImagesUpload",
  props: ["images", "productName"],
  components: {
    ProductImageUploadEmptyState: () =>
      import("./ProductImageUploadEmptyState.vue"),
    draggable,
    AltTagModal: () => import("./AltTagModal.vue"),
  },
  data() {
    return {
      hover: {},
      image_loading: false,
      uploadValue: null,
      newImage: null,
      src: null,
      altDialog: {},
    };
  },
  methods: {
    uploadNewImage(event) {
      this.image_loading = true;
      this.uploadValue = 0;

      // Rename image for SEO
      const SeoName = `${this.productName}_${event.target.files[0].name}`;
      const storageRef = firebase
        .storage()
        .ref(`ecommerce-product-img/${SeoName}`)
        .put(event.target.files[0]);
      // Get upload progress percentage
      storageRef.on(
        "state_changed",
        (snapshot) => {
          this.uploadValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        async () => {
          this.uploadValue = 100;
          // Firebase extension resizes and renames the image
          // We need to rename the file to get the correct storage ref
          const newFileName = (image) => {
            let updatedFile;
            const extensions = [
              ".png",
              ".jpg",
              ".jpeg",
              ".PNG",
              ".JPG",
              ".JPEG",
            ];
            extensions.forEach((el) => {
              if (!image.includes(el)) return;
              updatedFile = image.replace(el, "_575x575.webp");
            });
            return updatedFile;
          };
          //    Create new storage reference
          const newRef = firebase.storage().ref(newFileName(SeoName));
          //    Make calls to the storage bucket until it finds the new
          //    storage reference
          let url;
          try {
            url = await storageRef.snapshot.ref.getDownloadURL();
          } catch (error) {
            console.log(error);
          } finally {
            this.$emit("saveImgSrc", {
              url: url,
              resized: newRef.name,
              alt: "",
            });
            this.image_loading = false;
            this.uploadValue = 0;
          }
        }
      );
    },
    // Open image URL in new tab
    previewImage(URL) {
      window.open(URL, "_blank");
    },
  },
};
</script>
<style scoped>
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
}
.flex-container > div {
  max-width: calc(25% - 1em);
  width: calc(25% - 1em);
  margin: 0.5em;
}
</style>